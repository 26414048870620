import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import Router, { redirectIfLoginSubdomain } from './app/router';
import { ConfigureSentry } from './configureSentry';
import SentryErrorFallback from '../src/components/sentry-error-fallback';
import { unstable_ClassNameGenerator } from '@mui/material/className';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

unstable_ClassNameGenerator.configure(componentName => componentName.replace(/^Mui/, 'Sdk-Mui'));

redirectIfLoginSubdomain();

if (window.location.hash.includes('access_token')) {
  import('@aiware/shared/redux').then(({ stashTokenInSession }) => {
    stashTokenInSession();
  });
} else {
  const queryClient = new QueryClient();
  const root = ReactDOM.createRoot(document.getElementById('root')!);

  // Lazy load the dependencies to avoid loading them in the main bundle
  Promise.all([
    import('@aiware/shared/store'), // import { store } from '@aiware/shared/store';
    import('@aiware/shared/intl'), // import { AIWareIntlProvider } from '@aiware/shared/intl';
    import('@aiware/shared/theme'), // import { AIWareThemeProvider } from '@aiware/shared/theme';
    import('@aiware/os/auth'), // import { getConfig } from '@aiware/os/auth';
  ]).then(([{ store }, { AIWareIntlProvider }, { AIWareThemeProvider }, { getConfig }]) => {
    const { baseUrl, sentryDSN, environmentName } = getConfig() || {};
    root.render(
      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <AIWareThemeProvider>
              <AIWareIntlProvider>
                <ConfigureSentry sentryDSN={sentryDSN} environmentName={environmentName}>
                  <ErrorBoundary fallback={({ error }) => <SentryErrorFallback error={error} />}>
                    <Router baseUrl={baseUrl} />
                  </ErrorBoundary>
                </ConfigureSentry>
              </AIWareIntlProvider>
            </AIWareThemeProvider>
          </Provider>

          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
        </QueryClientProvider>
      </StrictMode>
    );
  });
}
